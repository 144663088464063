window.jQuery = window.$ = require("jquery");
require('owl.carousel/src/js/owl.carousel');
const $C = (el) => document.querySelector(el);

/**
 * Add select_item event to GTM dataLayer
 */
let productTiles = document.querySelectorAll('#product-search-results .product');
window.dataLayer = window.dataLayer || [];

function setEventListener(e,index) {
  let gtmProdData = this.getAttribute('data-gtmga4data');
  let gtmProdDataObj = JSON.parse(gtmProdData);
  let event = "select_item";
  let gtmData = {
    "event": event,
    "ecommerce": {
      "cart_id": gtmProdDataObj.cart_id,
      "date_created_cart": gtmProdDataObj.date_created_cart,
      "date_update_cart": gtmProdDataObj.date_update_cart,
      "total_quantity_cart": gtmProdDataObj.total_quantity_cart,
      "total_price_cart": gtmProdDataObj.total_price_cart,
      "currencyCode": gtmProdDataObj.currencyCode,
      "items": [{
        "item_id": gtmProdDataObj.item_id,
        "item_name": gtmProdDataObj.item_name,
        "item_variant": gtmProdDataObj.item_variant,
        "price_without_discount": gtmProdDataObj.price_without_discount,
        "is_in_stock": gtmProdDataObj.is_in_stock,
        "is_on_sale": gtmProdDataObj.is_on_sale,
        "item_list_name": gtmProdDataObj.item_list_name,
        "discount": gtmProdDataObj.discount,
        "item_brand": gtmProdDataObj.item_brand,
        "size": gtmProdDataObj.size,
        "quantity": gtmProdDataObj.quantity,
        "added_date": gtmProdDataObj.added_date,
        "item_list_id": gtmProdDataObj.item_list_id,
        "image": gtmProdDataObj.image,
        "item_category": gtmProdDataObj.item_category,
        "price": gtmProdDataObj.price,
        "type": gtmProdDataObj.type,
        "index": index,
        "currency": gtmProdDataObj.currencyCode
      }]
    }
  };
  window.dataLayer.push(gtmData);
}

window.onload = function () {
  productTiles?.forEach(function (productTile,index) {
    productTile.addEventListener('click', function (e) {
      setEventListener.call(productTile, e, index);
    });
  });
};

/**
 * End select_item event to GTM dataLayer
 */

/* PLP */
// SHOW MORE BUTTON

var plpItems = document.querySelectorAll('#product-search-results .product');
plpItems.forEach(function (item) {
  item.addEventListener('click', function (e) {
    sessionStorage.setItem('fromPLP', true);
  });
});
let showMoreButton = $C(".grid-footer .show-more button");

let changeSize = false;
if (showMoreButton) {
  window.addEventListener("scroll", () => {
    if (
      window.scrollY >=
      document.body.clientHeight - document.body.clientHeight * 0.6 &&
      !changeSize
    ) {
      if (showMoreButton) showMoreButton.click();
      changeSize = true;
      setTimeout(() => {
        productTiles = document.querySelectorAll('.product-grid .product');
        productTiles.forEach(function (item) {
          item.removeEventListener('click', setEventListener);
          item.addEventListener('click', setEventListener);
        });
        changeSize = false;
        showMoreButton = $C(".grid-footer .show-more button");
        // console.log(showMoreButton);
      }, 1000);
    }
  });
}


const productId = document.querySelector(".productID .productID__id");

if (productId) {
  $('.select-talla').on("click", () => {
    if ($('li.select-colors').hasClass('actives')) {
      setTimeout(() => {
        const productId = document.querySelector(
          ".productID .productID__id"
        );

        const SKU = document.querySelector(".productSku .product-id");
        if (productId.innerText != SKU.innerText) {
          $(".productSku").removeClass("d-none");
        }
      }, 500);

      $('.list-talla').on("click", () => {
        setTimeout(() => {
          const productId = document.querySelector(
            ".productID .productID__id"
          );
          const SKU = document.querySelector(".productSku .product-id");

          if (productId.innerText != SKU.innerText) {
            $(".productSku").removeClass("d-none");
          }
        }, 500);
      });
    }
  })
}


document.addEventListener("DOMContentLoaded", function (event) {
  var priceTotalValue = $('#cart-price-total').val();
  if (priceTotalValue) {
    var priceWithoutDollar = priceTotalValue.replace(',', '').replace('$', '')
    var priceAsInteger = parseInt(priceWithoutDollar).toFixed(); // Assuming the price is in dollars and cents
    if (priceAsInteger < 300.00) {
      $('#paymentType').find("option:contains('3')").hide();
      $('#paymentType').find("option:contains('6')").hide();
      $('#paymentType').find("option:contains('9')").hide();
    } else if (priceAsInteger >= 300.00 && priceAsInteger < 600.00) {
      $('#paymentType').find("option:contains('6')").hide();
      $('#paymentType').find("option:contains('9')").hide();
    } else if (priceAsInteger >= 600.00 && priceAsInteger < 900.00) {
      $('#paymentType').find("option:contains('9')").hide();
    }
  }
});


function handleNavbarScroll() {
  $(window).scroll(function () {
    if ($(window).scrollTop() > 75) {
      $('.navbar').addClass('navbar-fixed');
    }
    if ($(window).scrollTop() < 75) {
      $('.navbar').removeClass('navbar-fixed');
    }
  });
}

function initNavbar() {
  if ($(window).scrollTop() > 75) {
    $('.navbar').addClass('navbar-fixed');
  }
  
  if ('${pdict.page.ID}' !== 'homepage') {
    $('.navbar').removeClass('navbar-light');
    $('.navbar').addClass('navbar-other logoheader');
  }

  $('.container-fluid').click(function(){
    if ($('nav').hasClass('navbar-other-dropdown')) {
      $('.dropdown').children('ul').removeClass('d-block');
      $('.navbar').removeClass('navbar-other');
      $('.navbar').addClass('navbar-other-dropdown');
    }
  });

  $(".minicart").hover(
    function() { $('#popover').show(); },
    function() { $('#popover').hide(); }
  );
}

function initMegaMenu() {
  $('.display-menu-data').click(function(){
    let category = $(this).attr('data-id');
    let subid = $(this).attr('data-subid');
    let closeid = $(this).attr('data-close-id');
    if (closeid == '0') {
      $('.' + category + ' div .' + category + '-hide').hide();
      $('.' + subid + '-' + category + '-sub-category').addClass('display-flex');
    } else {
      $('.' + closeid).removeClass('display-flex');
      $('.' + category + ' div .' + category + '-hide').show();
    }
    return false;
  });

  $('.level-one').on('click', function(){
    let id = $(this).attr('id');
    window.location = $('#' + id).attr('href');
  });
}

function initSearchBox() {
  $('.search-icon-down').click(function() {
    $(".box-search").addClass("fullbox");

    if ($('.box-search').is(':hidden')) {
      $('.box-search').show();
      $('.navbar').addClass('navbar-other');
    } else {
      $('.box-search').hide();
      $('.navbar').removeClass('navbar-other');
    }
  });

  $('.search-cross').click(function() {
    $('.box-search').hide();
    $('.navbar').removeClass('navbar-other');
  });
}

if('${pdict.page.ID}' == 'homepage'){
}
else{
  $('.navbar').removeClass('navbar-light');
  $('.navbar').addClass('navbar-other');
  $('.navbar').addClass('logoheader');
}
 $('.owl-carousel').owlCarousel({
     margin:5,
     autoplayTimeout:3000,
     autoplayHoverPause:false,
     autoWidth:false,
     nav:false,
     loop:true,
     dots:true,
     responsive:{
         0:{
             items:2,
             nav:true
         },
         600:{
             items:2,
             nav:false
         },
         1000:{
             items:2,
             nav:true,
             loop:false
         }
     }
 })
 
module.exports = () => {
  handleNavbarScroll();
  initNavbar();
  initMegaMenu();
  initSearchBox();
};
